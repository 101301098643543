/**
 * Get upsell selected products to has been added to cart
 * @param {object} state - The vuex state object
 * @return {Object[]} Get upsell selected products to has been added to cart
 */
export const getCartProducts = (state) => state.upsellPayload.orders;

/**
 * Get upsell page props value
 * @param {object} state - The vuex state object
 * @return {object} upsell page props value
 */
export const getUpsellPageProps = (state) => state.pageProps;

/**
 * Get the previous order id from the subscription payment
 * @param {object} state - The vuex state object
 * @return {string} previous order id value
 */
export const getUpsellPreviousOrderId = (state) => state.upsellPayload.payment.previousOrderId;

/**
 * Get global event name
 * @param {object} state - The vuex state object
 * @return {String} Global event name
 */
export const getGlobalEventName = (state) => state.globalEventName;

/**
 * Get UpsellDownsale
 * @param {object} state - The vuex state object
 * @return {String} UpsellDownsale
 */
export const getUpsellDownsale = (state) => state.upsellPayload.upsellDownsale;

export const getUpsellStandalone = (state) => state.upsellStandalone;

export const getUpsellStandaloneBought = (state) => state.upsellStandaloneBought;

/**
 * isStandaloneUpsell
 * @param {object} state - The vuex state object
 * @return {boolean} return `true` when user is purchasing an standalone upsell
 */
export const isStandaloneUpsell = (state) => !!state.upsellStandalone.length;
