const merge = require('lodash/merge');
const usConfig = require('./regions/default.config.json');
const usCopys = require('./translations/en.json');
const quiz = require('./quiz/en.json');
const config = require('./regions/nz.config.json');
const copys = require('./translations/en-NZ.json');
const price = require('./prices/nz.json');
const units = require('./translations/en.metric.json');

const locale = {};
merge(locale, usConfig);
merge(locale, usCopys);
merge(locale, units);
merge(locale, config);
merge(locale, quiz);
merge(locale, copys);
merge(locale, price);
export default locale;
