import { COOKIES } from '@/services/constants';
import { Helpers } from '@/services/helpers';

export default function ({ $cookies, redirect, store, route, query }) {
  const token = $cookies.get(COOKIES.token);

  if (!token) {
    const { path } = route;
    const { redirect_path: redirectPath, redirect_url: redirectUrl } = query;

    store.dispatch('auth/resetAuthData');
    return redirect(
      Helpers.getLoginUrl({
        ...((path || redirectPath) && { redirectPath: path || redirectPath }),
        ...(redirectUrl && { redirectUrl }),
      }),
    );
  }
}
