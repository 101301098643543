export const getCheckoutIdByRegionAndQuizIdHandler = (checkoutRepository) => ({
  execute: async ({ region, vertical, type, pricingModel, variant = null }, { onSuccess, onFailure }) => {
    try {
      const { checkoutId } = await checkoutRepository.getCheckoutIdByRegionAndQuizId({
        region,
        vertical,
        type,
        pricingModel,
        variant,
      });

      if (!checkoutId) {
        onFailure();
        return;
      }

      onSuccess(checkoutId);
    } catch {
      onFailure();
    }
  },
});
