const merge = require('lodash/merge');
const usConfig = require('./regions/default.config.json');
const config = require('./regions/latam.config.json');
const quiz = require('./quiz/es.json');
const usCopys = require('./translations/es.json');
const copys = require('./translations/es-LATAM.json');
const price = require('./prices/latam.json');
const units = require('./translations/es.metric.json');

const locale = {};
merge(locale, usConfig);
merge(locale, config);
merge(locale, usCopys);
merge(locale, units);
merge(locale, quiz);
merge(locale, price);
merge(locale, copys);

export default locale;
