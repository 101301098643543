import * as actions from './actions';

const mutations = {};

const state = () => ({});

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
