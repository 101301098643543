export default function ({ $axios, $config }, inject) {
  /**
   * Create axios instance for firebase cloud functions
   * @type {AxiosInstance}
   * @return {AxiosInstance}
   */
  const API = $axios.create({
    baseURL: $config.firebaseCloudFunctionsURL,
  });

  /**
   * Create axios instance for handling payment
   * @property {Headers}: Authorization its base64 for the basic auth {username, password}
   * @type {string}
   * @return {AxiosInstance}
   */
  const PAYMENT = $axios.create({
    baseURL: $config.stickyURL,
  });

  /**
   * Create axios instance for firebase cloud functions
   * @type {AxiosInstance}
   * @return {AxiosInstance}
   */
  const LOGIN = $axios.create({
    baseURL: $config.suggesticURL,
    headers: {
      'Suggestic-Partner': 'reverse_health',
    },
  });

  // Inject to context as $API
  inject('api', API);

  // Inject to context as $PAYMENT
  inject('payment', PAYMENT);

  // Inject to context as $LOGIN
  inject('login', LOGIN);
}
