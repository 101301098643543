export const getPageNameByUrl = (url) => {
  const urlPath = new URL(url).pathname;
  const trimmedSlashesPath = urlPath.replace(/^\/+|\/+$/g, '');

  return trimmedSlashesPath
    .split('/')
    .map((p) => `${p.charAt(0).toUpperCase()}${p.slice(1)}`)
    .join(': ');
};

export const buildUrlParameters = (params) => {
  return Object.entries(params)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};
