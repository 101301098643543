/**
 * This function changes the language of the app depending on:
 *   - `lang` query parameter
 *   - `accept-language` request header
 *
 * If `lang` query parameter is present, it will be the one used, no matter what is coming inside the `accept-language`
 * request header.
 *
 * In case `lange` query parameter doesn't exist, `accept-language` header will be parsed and extracted a list of
 * languages from it. That list will be compared to the list of locales that the app has in the `i18n` plugin, and if
 * found any compatible, will load it.
 *
 * This is an example of `accept-language` request header:
 *   accept-language: es,en;q=0.9,en-GB;q=0.8,en-US;q=0.7
 */
import enAU from '../locales/en-AU';
import enBR from '../locales/en-BR';
import enCA from '../locales/en-CA';
import enEU from '../locales/en-EU';
import enJOIN from '../locales/en-JOIN';
import enLATAM from '../locales/en-LATAM';
import enNZ from '../locales/en-NZ';
import enSA from '../locales/en-SA';
import enUK from '../locales/en-UK';
import enUS from '../locales/en-US';
import esEU from '../locales/es-EU';
import esJOIN from '../locales/es-JOIN';
import esLATAM from '../locales/es-LATAM';
import esUS from '../locales/es-US';
import ptBR from '../locales/pt-BR';
import deEU from '../locales/de-EU';
import ptEU from '../locales/pt-EU';
import deUS from '../locales/de-US';
import frEU from '../locales/fr-EU';
import frUS from '../locales/fr-US';
import itEU from '../locales/it-EU';
import itUS from '../locales/it-US';
import nlEU from '../locales/nl-EU';
import nlUS from '../locales/nl-US';
import ptUs from '../locales/pt-US';
import elEU from '../locales/el-EU';
import elUS from '../locales/el-US';
import plEU from '../locales/pl-EU';
import plUS from '../locales/pl-US';
import svEU from '../locales/sv-EU';
import svUS from '../locales/sv-US';

import { COOKIES } from '@/services/constants';

const availableLocales = {
  'en-AU.js': enAU,
  'en-BR.js': enBR,
  'en-CA.js': enCA,
  'en-EU.js': enEU,
  'en-JOIN.js': enJOIN,
  'en-LATAM.js': enLATAM,
  'en-NZ.js': enNZ,
  'en-SA.js': enSA,
  'en-UK.js': enUK,
  'en-US.js': enUS,
  'es-EU.js': esEU,
  'es-JOIN.js': esJOIN,
  'es-LATAM.js': esLATAM,
  'es-US.js': esUS,
  'pt-BR.js': ptBR,
  'de-EU.js': deEU,
  'pt-EU.js': ptEU,
  'de-US.js': deUS,
  'fr-EU.js': frEU,
  'fr-US.js': frUS,
  'it-EU.js': itEU,
  'it-US.js': itUS,
  'nl-EU.js': nlEU,
  'nl-US.js': nlUS,
  'pt-US.js': ptUs,
  'el-EU.js': elEU,
  'el-US.js': elUS,
  'pl-EU.js': plEU,
  'pl-US.js': plUS,
  'sv-EU.js': svEU,
  'sv-US.js': svUS,
};

export default async function ({ app, $cookies, route }) {
  try {
    const { $countryCode } = app;
    const lang = route.query.lang || $cookies.get(COOKIES.lang) || '';
    // TODO: uncomment this block when deploying browser lang detection
    // const headers = process.client
    //   ? {
    //       'accept-language': navigator.languages.join(';'),
    //     }
    //   : req.headers;

    // const [acceptLanguage] = (headers['accept-language'] || '').split(';').reduce((acc, acceptLanguagePart) => {
    //   return [
    //     ...acc,
    //     ...acceptLanguagePart
    //       .split(',')
    //       .filter(
    //         (acceptLanguagePart) =>
    //           acceptLanguagePart.match(/^[a-z]{2}-[a-z]{2}$/) || acceptLanguagePart.match(/^[a-z]{2}$/),
    //       )
    //       .map((acceptLanguageSubpart) => acceptLanguageSubpart.toLowerCase()),
    //   ];
    // }, []);

    if (lang) {
      $cookies.set(COOKIES.lang, lang, {
        maxAge: 60 * 60 * 24 * 7,
        path: '/',
        secure: process.env.NODE_ENV !== 'development',
      });
    }

    const acceptLanguage = null; // TODO: remove this when deploying browser lang detection
    const formattedAcceptLanguage = lang
      ? `${lang}-${$countryCode.toLowerCase()}`
      : acceptLanguage
        ? `${
            acceptLanguage.length === 2 ? acceptLanguage : acceptLanguage.split('-').shift().toLowerCase()
          }-${$countryCode.toLowerCase()}`
        : `en-${$countryCode.toLowerCase()}`;

    if (formattedAcceptLanguage) {
      const locale =
        app.i18n.locales.find((locale) => locale.acceptedLanguage === formattedAcceptLanguage) ||
        app.i18n.locales.find((locale) => locale.acceptedLanguage === `en-${$countryCode.toLowerCase()}`) ||
        app.i18n.locales.find((locale) => locale.acceptedLanguage === 'en-us');

      if (locale) {
        try {
          const foundLocale = availableLocales[locale.file];

          if (!foundLocale) {
            throw new Error(`Invalid locale ${locale.file}`);
          }

          app.i18n.locale = locale.code;
          app.i18n.defaultLocale = locale.code;
          await app.i18n.setLocale(locale.code);
          await app.i18n.setLocaleMessage(locale.code, foundLocale);
          console.log('Language switched to', locale.code);
        } catch (importError) {
          console.error('Error using a not valid language', importError);
        }
      }
    }
  } catch (globalError) {
    console.error('Something went wrong while parsing language', globalError);
  }
}
