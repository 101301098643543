export default function ({ route, $gtm, $config }) {
  if (route.query.disableGTM) {
    sessionStorage.setItem('disableGTM', 'true');
  }

  const disableGTM = sessionStorage.getItem('disableGTM') === 'true';

  if (!disableGTM) {
    $gtm.init($config.gtm.id);
  }
}
