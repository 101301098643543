export default {
  name: 'dummy-offer-test',
  includedRoutes: ['/payment-options', '/payment-options/'],
  excludedUtmSources: ['tiktok'],
  allocateOnClient: true,
  variants: [
    {
      dummy: 0,
    },
    {
      dummy: 998,
    },
    {
      dummy: 999,
    },
  ],
};
