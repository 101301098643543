import { SET_DETECTED_COUNTRY, SET_CURRENT_DOMAIN_PREFIX } from '../mutation-types';
import * as actions from './actions';
import * as getters from './getters';

const mutations = {
  [SET_DETECTED_COUNTRY](state, payload) {
    state.detectedCountry = payload;
  },
  [SET_CURRENT_DOMAIN_PREFIX](state, payload) {
    state.currentDomainPrefix = payload;
  },
};

const state = () => ({
  isUrlContainDetectedCountryParam: false,
  detectedCountry: null,
  currentDomainPrefix: null,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
