import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.NUTRITION_NEW_CHECKOUT,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/authority-related-testimonials-body-nutrition',
    '/quiz/authority-related-testimonials-body-nutrition',
  ],
  allocateOnClient: true,
  variants: [
    {
      shouldRedirect: false,
    },
    {
      shouldRedirect: true,
    },
  ],
};
