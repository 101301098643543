import { COOKIES } from '@/services/constants';

const helpCenterUrl = 'https://help.reverse.health/';

export default function ({ $cookies, app }, inject) {
  const getHelpCenterUrl = () => {
    const langCookies = $cookies.get(COOKIES.lang) || null;
    const usedLocale = app.i18n.locales.find((locale) => locale.code === app.i18n.locale);
    const lang = usedLocale ? usedLocale.acceptedLanguage.substring(0, 2) : null;

    return lang ? `${helpCenterUrl}${lang}` : langCookies ? `${helpCenterUrl}${langCookies}` : helpCenterUrl;
  };

  inject('getHelpCenterUrl', getHelpCenterUrl);
}
