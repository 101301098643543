import { Helpers } from '../services/helpers';

export const getUtmObject = () => {
  if (process.client) {
    try {
      return JSON.parse(window?.sessionStorage.getItem('utmObject')) || {};
    } catch (e) {
      this.$sentry.captureMessage(new Error('Error occurred druing getting items from sessionStorage', 'error'));
      return {};
    }
  }
};

export const setUtmObject = (utmObject) => {
  try {
    const cleanUtmObject = Helpers.cleanEmptyKeysFromObj(utmObject);

    window?.sessionStorage.setItem('utmObject', JSON.stringify(cleanUtmObject));
  } catch {
    this.$sentry.captureMessage(new Error('Error occurred druing setting items from sessionStorage', 'error'));
    console.error('Cannot set UTM object');
  }
};
