export default {
  name: 'dummy-tour-test',
  includedRoutes: ['/tour', '/tour/', '/tour/optimal-weight-program', '/tour/optimal-weight-program/'],
  excludedUtmSources: ['tiktok'],
  variants: [
    {
      tour: 0,
      path: '/tour/0',
    },
    {
      tour: 998,
      path: '/tour/998',
    },
    {
      weight: 1,
      tour: 999,
      path: '/tour/999',
    },
  ],
};
