import { datadogLogs } from '@datadog/browser-logs';

export const getLoggerService = () => {
  const desiredLogger =
    process.env.NUXT_ENV_APP_ENV !== 'production' || process.env.CI || process.env.IS_CI ? console : datadogLogs.logger;

  const log = (message, metadata, severity) => {
    desiredLogger[severity](message, {
      data: {
        jsonPayload: {
          message,
          metadata,
        },
      },
    });
  };

  const logger = {
    log: (message, metadata) => {
      log(message, metadata, 'info');
    },
    debug: (message, metadata) => {
      log(message, metadata, 'debug');
    },
    info: (message, metadata) => {
      log(message, metadata, 'info');
    },
    warn: (message, metadata) => {
      log(message, metadata, 'warn');
    },
    error: (message, metadata) => {
      log(message, metadata, 'error');
    },
  };

  return { logger };
};
