import jwt from 'jsonwebtoken';

export const state = () => ({
  localeDomains: {
    us: process.env.DOMAIN,
    uk: 'uk.' + process.env.DOMAIN,
    eu: 'eu.' + process.env.DOMAIN,
    join: 'join.' + process.env.DOMAIN,
    ca: 'ca.' + process.env.DOMAIN,
    au: 'au.' + process.env.DOMAIN,
    nz: 'nz.' + process.env.DOMAIN,
    sa: 'sa.' + process.env.DOMAIN,
  },
});

export const actions = {
  nuxtServerInit({ dispatch }, { $cookies }) {
    const token = $cookies.get('token');

    if (token) {
      const data = jwt.decode(token);

      dispatch('auth/setUserLoginEmailAction', data.email);
      dispatch('auth/setJwtData', data);
    }
  },
};
