export const useIntercom = ({ intercomAppID, intercomSecretKey }) => {
  const boot = ({ email, userId }) => {
    if (!window?.Intercom) return;

    const intercomConfig = {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppID,
    };

    if (email && userId) {
      const crypto = require('crypto');
      const hash = crypto.createHmac('sha256', intercomSecretKey).update(userId).digest('hex');

      Object.assign(intercomConfig, {
        user_id: userId,
        email,
        user_hash: hash,
      });
    }

    window.Intercom('boot', intercomConfig);
    update();
  };

  const update = () => {
    if (!window?.Intercom) return;

    window.Intercom('update', {
      hide_default_launcher: false,
    });
  };

  return { boot, update };
};
