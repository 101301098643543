import URLS from '@/services/urls';
import { SET_PHONE_VERIFIED } from '@/store/mutation-types';

export async function requestPhoneValidationCode(_, phoneNumber) {
  try {
    await this.$api.$post(URLS.REQUEST_PHONE_CODE, {
      phoneNumber,
    });
    return { success: true };
  } catch (err) {
    this.$sentry.captureException(err);
    if (err.response && err.response.status === 422) {
      return {
        success: false,
        statusCode: err.response.status,
        message: err.response.data.message,
      };
    }
    return {
      success: false,
      statusCode: err.response ? err.response.status : 500,
      message: this.$t('checkout.meds.shippingInformation.errorValidatingPhone'),
    };
  }
}

export async function validatePhoneValidationCode(_, payload) {
  const phoneNumber = payload?.phoneNumber ?? '';
  const code = payload?.code ?? '0000';

  try {
    return await this.$api.$post(URLS.VERIFY_PHONE_CODE, {
      phoneNumber,
      code,
    });
  } catch (err) {
    this.$sentry.captureException(err);
    return err?.response;
  }
}

export function setPhoneNumberVerified({ commit }, payload) {
  commit(SET_PHONE_VERIFIED, payload);
}
