import VuexPersistence from 'vuex-persist';

export default ({ store, route }) => {
  if (
    (route.path !== '/checkout' && route.path !== '/paypal-callback' && route.path !== '/payment-options') ||
    (route.query?.email && !route.query.alt_pay_method)
  ) {
    window.localStorage.removeItem('vuex');
  }

  new VuexPersistence({
    storage: window.localStorage,
    modules: ['payment', 'detected-country', 'quiz', 'have-lead', 'active-subscription'],
  }).plugin(store);
};
