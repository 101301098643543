const ENGLISH_LOCALE_WORKAROUND = Object.freeze({
  us: 'us',
  en: 'en',
});

export const getLangFromLocale = (locale) => {
  const normalizedLocale = locale.toLowerCase();
  const currentLocale = normalizedLocale.split('-')[0];
  return currentLocale === ENGLISH_LOCALE_WORKAROUND.us ? ENGLISH_LOCALE_WORKAROUND.en : currentLocale;
};
