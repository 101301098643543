import Cookies from 'cookie-universal';
import { checkoutRepository } from '@/modules/checkout/infrastructure/checkout.repository';
import { getCheckoutIdByRegionAndQuizIdHandler } from '@/modules/checkout/application/get-checkout-id-by-region-and-tour-id';
import { getLoggerService } from '@/services/logger';

const cookies = Cookies();
const { logger } = getLoggerService();
const getCheckoutIdByRegionAndQuiz = getCheckoutIdByRegionAndQuizIdHandler(checkoutRepository);

function sendLog({
  message,
  tag,
  checkoutId,
  vertical,
  type,
  quizId,
  region,
  uuid,
  experiments,
  source,
  pricingModel,
}) {
  logger.info(message, {
    tags: [tag],
    meta: {
      region,
      lead: {
        uuid,
        quizId,
        experiments,
        source,
      },
      checkout: {
        id: checkoutId,
        vertical,
        type,
        pricingModel,
      },
    },
  });
}

export function logEventWithQuizMeta({ rootState }, { message, tag, meta = {} }) {
  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const [vertical, type] = product.productType.split('-');
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  sendLog({ message, tag, vertical, type, quizId, region, meta, uuid, experiments, source, pricingModel });
}

export async function logEventWithCheckoutMeta({ rootState }, { message, tag }) {
  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const [vertical, type] = product.productType.split('-');
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  await getCheckoutIdByRegionAndQuiz.execute(
    { region, vertical, type, pricingModel },
    {
      onSuccess: (checkoutId) => {
        sendLog({ message, tag, checkoutId, vertical, type, quizId, region, uuid, experiments, source, pricingModel });
      },
      onFailure: () => {
        sendLog({
          message,
          tag,
          checkoutId: 'old',
          vertical,
          type,
          quizId,
          region,
          uuid,
          experiments,
          source,
          pricingModel,
        });
      },
    },
  );
}

export async function logUniqueEventWithCheckoutMeta({ dispatch }, { message, tag, cookieName }) {
  const cookieValue = cookies.get(cookieName);

  if (cookieValue) return;

  await dispatch('logEventWithCheckoutMeta', { message, tag });
  cookies.set(cookieName, true);
}
