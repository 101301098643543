/**
 *
 * @param {*} id string
 * @param {*} desc string
 * @param {*} regions string[] | undefined
 * @param {*} seq string[]
 * @returns Upsell
 */
export const createUpsell = ({ id, desc, regions, seq }) => {
  return Object.freeze({
    id,
    description: desc,
    regions,
    sequence: seq,
  });
};

export const upsellsEnum = Object.freeze({
  yoga: 'yoga',
  healthyBack: 'healthyBack',
  yogaAndHealthyBack: 'yogaAndHealthyBack',
});
