/**
 * Get selected payment
 * @param {object} state - The vuex state object
 * @return {object} Selected payment
 */
export const getSelectedPayment = (state) => state.selectedPayment;

/**
 * Get checkout response
 * @param {object} state - The vuex state object
 * @return {object} Checkout response
 */
export const getCheckoutResponse = (state) => state.checkoutResponse;

/**
 * Get user email
 * @param {object} state - The vuex state object
 * @return {string} Checkout response
 */
export const getUserEmail = (state) => state.paymentPayload.email;

/**
 * Get user email
 * @param {object} state - The vuex state object
 * @return {string} Checkout response
 */
export const getPaymentMethod = (state) => state.paymentPayload.payment?.method;

/**
 * Get countries list
 * @param {object} state - The vuex state object
 * @return {Object[]} List of countries
 */
export const getCountries = (state) => state.countries;

/**
 * Get regions list
 * @param {object} state - The vuex state object
 * @return {Object[]} List of countries
 */
export const getRegions = (state) => state.regions;

/**
 * Get component name value
 * @param {object} state - The vuex state object
 * @return {string} Component name value
 */
export const getComponentName = (state) => state.componentName;

/**
 * Get payment error value
 * @param {object} state - The vuex state object
 * @return {Boolean} payment error value
 */
export const getPaymentHasError = (state) => state.paymentHasError;

/**
 * Get credit card details
 * @param {object} state - The vuex state object
 * @return {Object} credit card details
 */
export const getCcDetails = (state) => state.ccDetails;

/**
 * Get 3d secure required status
 * @param {object} state - The vuex state object
 * @return {Boolean} 3d secure required status
 */
export const getIs3DSecureRequired = (state) => state.is3DSecureRequired;

/**
 * Get 3d secure iframe url
 * @param {object} state - The vuex state object
 * @return {String} 3d secure iframe url
 */
export const get3DSecureURL = (state) => state.threeDSecureUrl;

/**
 * Get all paid und unpaid order
 * @param {object} state - The vuex state object
 * @return {Object[]} Paid und unpaid order summary
 */
export const getOrderSummary = (state) => state.orderSummary;

/**
 * Get selected variant
 * @param {object} state - The vuex state object
 * @return {Number} Payment Variant
 */
export const getSelectedVariant = (state) => state.paymentPayload.payment.variant;

/**
 * Get selected variant
 * @param {object} state - The vuex state object
 * @return {Number} Payment Variant
 */
export const getSelectedType = (state) => state.paymentPayload.payment.type;

/**
 * Get affiliates
 * @param {object} state - The vuex state object
 * @return {Boolean} Affiliates
 */
export const getAffiliates = (state) => state.affiliates;

/**
 * Get coupon code
 * @param {object} state - The vuex state object
 * @return {Object} Coupon code
 */
export const getCouponCode = (state) => state.couponCode;

export const getApplePayInstance = (state) => state.applePayInstance;

export const getApplePayNonce = (state) => state.applePayInstance;

export const getApplePayWalletToken = (state) => state.applePayWalletToken;

export const getUserFirstName = (state) => state.paymentPayload.firstName;

export const getUserLastName = (state) => state.paymentPayload.lastName;

export const hasPurchased12MonthUpsell = (state) =>
  state.orderSummary?.paid?.some((upsell) => upsell.id === 'upsell12Month');
