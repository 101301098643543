import { useDomain } from '@/composables/useDomain';

export default function (context, inject) {
  const hostname = process.client
    ? window.location.hostname
    : context.req.headers['x-original-host'] || context.req.headers.host;

  const domain = useDomain(hostname);

  const countryCode = domain.getCountryCode();

  if (process.env.NODE_ENV !== 'production') {
    console.log(`Country code: ${countryCode}`);
  }
  inject('countryCode', countryCode);

  if (process.server) {
    context.beforeNuxtRender(({ nuxtState }) => {
      nuxtState.host = context.req.headers.host;
      nuxtState.geo = {
        city: context.req.headers['cf-ipcity'],
        continent: context.req.headers['cf-ipcontinent'],
        ip: context.req.headers['cf-connecting-ip'],
        lat: context.req.headers['cf-iplatitude'],
        long: context.req.headers['cf-iplongitude'],
        postalCode: context.req.headers['cf-postal-code'],
        regionCode: context.req.headers['cf-region-code'],
        state: context.req.headers['cf-region'],
        timezone: context.req.headers['cf-timezone'],
        country: context.req.headers['cf-ipcountry'],
      };
    });
  } else {
    inject('host', context.nuxtState?.host);
    inject('geo', context.nuxtState?.geo);
    inject('isMississippi', context.nuxtState?.geo?.country === 'US' && context.nuxtState?.geo?.regionCode === 'MS');
  }
}
