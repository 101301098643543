import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PRICING_BR_EBANX,
  includedRoutes: ['/payment-options', '/payment-options/', '/checkout', '/checkout/'],
  excludedUtmSources: [],
  allocateOnClient: true,
  variants: [
    {
      pricing: 0,
    },
    {
      pricing: 130,
    },
  ],
};
