import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PRICING_PILATES_WEEKLY,
  includedRoutes: ['/payment-options', '/payment-options/', '/checkout', '/checkout/'],
  allocateOnClient: true,
  variants: [
    {
      pricing: 200,
    },
    {
      pricing: 177,
    },
    {
      pricing: 178,
    },
  ],
};
