import URLS from '@/services/urls';
import { Helpers } from '@/services/helpers';

export default async function (context) {
  const { query } = context.route;
  if (query.hasOwnProperty('email')) {
    if (!query.debug) {
      const email = Helpers.fixEmailAddress(query.email);
      const enocoded = encodeURIComponent(email);
      await context.$api
        .$get(URLS.CHECK_LEAD_BY_EMAIL_DETAILS(enocoded))
        .then((response) => {
          if (response.lead) {
            const country = response.details.userAttrs.country || response.details.userAttrs.region;

            if (context?.$countryCode === country) {
              return false;
            }

            if (country === 'US') {
              context.redirect(`https://reverse.health${context.route.fullPath}`);
            } else {
              context.redirect(`https://${country}.reverse.health${context.route.fullPath}`);
            }
          }
        })
        .catch((err) => {
          context.$sentry.captureException(err);
          console.log(err);
        });
    }
  }
}
