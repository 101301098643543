import { QUIZ_VARIANTS, REGIONS } from '@/services/constants';

/**
 * [Checkouts config by ID](https://github.com/reversegroupio/reversehealth-funnel/tree/staging/apps/checkout/src/modules/checkout/domain/checkouts)
 */
const FITNESS_PILATES_WEEKLY_CHECKOUT_IDS = Object.freeze({
  sm17e2: 'sm17e2',
  t4l6co: 't4l6co',
  dYMi02: 'dYMi02',
  s4tP3p: 's4tP3p',
  gC37uJ: 'gC37uJ',
  b8d7fb: 'b8d7fb',
  j36NpE: 'j36NpE',
  ddWA03: 'ddWA03',
});

/**
 * [Docs to current checkout IDs by product and region](https://www.notion.so/reversetech/New-CheckOut-Id-s-a6391e0986af41ecba55d1a0de267eef)
 */
export const FITNESS_PILATES_WEEKLY_CHECKOUTS = Object.freeze([
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.sm17e2,
    region: REGIONS.us,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.t4l6co,
    region: REGIONS.uk,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.dYMi02,
    region: REGIONS.au,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.s4tP3p,
    region: REGIONS.join,
  },
]);

export const FITNESS_PILATES_WEEKLY_CHECKOUTS_EXPERIMENTS = Object.freeze([
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.sm17e2,
    region: REGIONS.us,
    variant: 0,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.gC37uJ,
    region: REGIONS.us,
    variant: 1,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.b8d7fb,
    region: REGIONS.us,
    variant: 2,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.s4tP3p,
    region: REGIONS.join,
    variant: 0,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.j36NpE,
    region: REGIONS.join,
    variant: 1,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: FITNESS_PILATES_WEEKLY_CHECKOUT_IDS.ddWA03,
    region: REGIONS.join,
    variant: 2,
  },
]);
