import { getLangFromLocale } from '@/services/locale';
import { buildUrlParameters } from '@/services/url';
import { CHECKOUT_IDS_BY_TOUR_AND_REGION } from '../domain/checkout';

export const getCheckoutIdPathByTourId = (
  { tourId, currentRegion, leadUuid, currentLocale, abTestExperiment = null, affiliate = null },
  { onSuccess, onFailure },
) => {
  if (!leadUuid) {
    onFailure();
    return;
  }

  const targetCheckoutData = CHECKOUT_IDS_BY_TOUR_AND_REGION.find(
    (c) => c.region === currentRegion && c.tourIds.includes(tourId),
  );

  if (!targetCheckoutData || !targetCheckoutData.checkoutId) {
    onFailure();
    return;
  }

  const locale = getLangFromLocale(currentLocale);

  const urlParams = buildUrlParameters({
    uuid: leadUuid,
    locale: locale,
    aff: affiliate,
    ev: abTestExperiment,
  });

  const newCheckoutBasePath = `${targetCheckoutData.checkoutId}/offers?${urlParams}`;

  onSuccess(newCheckoutBasePath);
};
