import { QUIZ_VARIANTS, REGIONS } from '@/services/constants';

/**
 * [Checkouts config by ID](https://github.com/reversegroupio/reversehealth-funnel/tree/staging/apps/checkout/src/modules/checkout/domain/checkouts)
 */
const FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS = Object.freeze({
  aCZnc3: 'aCZnc3',
  uEJ2rl: 'uEJ2rl',
  hJWVzF: 'hJWVzF',
  iCfF6N: 'iCfF6N',
  zWREHx: 'zWREHx',
  b0uEz4: 'b0uEz4',
  w1EBPu: 'w1EBPu',
  cTHQXH: 'cTHQXH',
});

/**
 * [Docs to current checkout IDs by product and region](https://www.notion.so/reversetech/New-CheckOut-Id-s-a6391e0986af41ecba55d1a0de267eef)
 */
export const FITNESS_PILATES_NOW_WEEKLY_CHECKOUTS = Object.freeze([
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.hJWVzF,
    region: REGIONS.us,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.iCfF6N,
    region: REGIONS.uk,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.uEJ2rl,
    region: REGIONS.au,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.aCZnc3,
    region: REGIONS.join,
  },
]);

export const FITNESS_PILATES_NOW_WEEKLY_CHECKOUTS_EXPERIMENTS = Object.freeze([
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.hJWVzF,
    region: REGIONS.us,
    variant: 0,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.w1EBPu,
    region: REGIONS.us,
    variant: 1,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.cTHQXH,
    region: REGIONS.us,
    variant: 2,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.aCZnc3,
    region: REGIONS.join,
    variant: 0,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.zWREHx,
    region: REGIONS.join,
    variant: 1,
  },
  {
    tourIds: [QUIZ_VARIANTS.PILATES_NOW],
    checkoutId: FITNESS_PILATES_NOW_WEEKLY_CHECKOUT_IDS.b0uEz4,
    region: REGIONS.join,
    variant: 2,
  },
]);
