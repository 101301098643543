/**
 * Get quiz list
 * @param {object} state - The vuex state object
 * @return {Array} Quiz list
 */
export const getQuizList = (state) => state.quizList;

/**
 * Get quiz current index
 * @param {object} state - The vuex state object
 * @return {Number} Current quiz index
 */
export const getQuizCurrentIndex = (state) => state.currentIndex;

/**
 * Get quiz total length
 * @param {object} state - The vuex state object
 * @return {Number} Quiz total length
 */
export const getQuizTotalLength = (state) => state.totalLength;

/**
 * Get quiz answer list that contain all selected answers
 * @param {object} state - The vuex state object
 * @return {Object[]} Quiz quiz answer list
 */
export const getQuizAnswerList = (state) => state.answers;

/**
 * Get header status
 * @param {object} state - The vuex state object
 * @return {Boolean} Firebase remote config for header status
 */
export const getHeaderStatus = (state) => state.headerStatus;

/**
 * Get user final weight
 * @param {object} state - The vuex state object
 * @return {String} Get user final weight
 */
export const getUserFinalWeight = (state) => +state.userAttrs.finalWeight.toFixed(2);

/**
 * Get intercom id value
 * @param {object} state - The vuex state object
 * @return {String} Intercom id value
 */
export const getIntercomId = (state) => state.intercomId;

/**
 * Get target loss value
 * @param {object} state - The vuex state object
 * @return {String} Target loss value
 */
export const getTargetLoss = (state) => state.targetLoss;

/**
 * Get user email
 * @param {object} state - The vuex state object
 * @return {String} user email
 */
export const getUserEmail = (state) => state.userAttrs.email;

export const getQuizVariant = (state) => state.quizVariant;

export const getLandingPageId = (state) => state.landingPageId;

/**
 * Get Quiz Referrer Link value
 * @param {object} state - The vuex state object
 * @return {String} Quiz Referrer Link value
 */
export const getQuizReferrerLink = (state) => state.quizReferrerLink;

export const getLeadUserReferrerLink = (state) => state.leadUserReferrerLink;

export const getUnits = (state) => state.units;

export const getProductType = (state) => state.productType;

export const getProduct = (state) => state.product;

export const getAnswerById = (state) => (id) => state.answers.find((item) => item.id === id)?.answer;

/**
 * Get user information
 * @param {object} state - The vuex state object
 * @return {Object} User information
 */
export const getUserInfo = (state) => state.answers.find(({ id }) => id === 'measurements');

/**
 * Get user age
 * @param {object} state - The vuex state object
 * @return {String} User age
 */
export const getUserAge = (state) => state.answers.find(({ id }) => id === 'measurements-age')?.answer?.age;

/**
 * Get quiz step from
 * @param {object} state - The vuex state object
 * @return {String} Quiz step from
 */
export const getQuizStepFrom = (state) => state.quizStepFrom;

/**
 * Get quiz step to
 * @param {object} state - The vuex state object
 * @return {String} Quiz step to
 */
export const getQuizStepTo = (state) => state.quizStepTo;

/**
 * Get quiz step to
 * @param {object} state - The vuex state object
 * @return {String} Quiz default value
 */
export const getDefaultStateValue = (state) => state.defaultStateValue;
