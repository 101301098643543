import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_EMAIL_CTA,
  includedRoutes: ['/tour/pilates-28-day-challenge', '/tour/pilates-28-day-challenge/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3}`,
    },
  ],
};
