import { Helpers } from '@/services/helpers';
import { COOKIES } from '@/services/constants';

export default function ({ route, redirect, $cookies, $api, $payment, $login, $sentry }) {
  // Use authentication if present:
  $api.interceptors.request.use(function (config) {
    const token = $cookies.get(COOKIES.token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  /**
   * Log all the request that been sent to
   * firebase cloud functions || sticky || suggestic
   * @return {any} request object for each request
   */
  $api.onRequest((config) => {
    console.log('Making request to  ' + config.url);
  });

  $payment.onRequest((config) => {
    console.log('Making request to ' + config.url);
  });

  $login.onRequest((config) => {
    console.log('Making request to ' + config.url);
  });

  /**
   * Request interceptor Error: will catch all request if there
   * is any error happening and log in to {Sentry}
   * @return {any} Error information if exist for each request
   */
  $api.onError((error) => {
    $sentry.captureException(error);
    if (error?.response?.status === 401) {
      return redirect(Helpers.getLoginUrl({ redirectPath: route.path }));
    }
    // Handle network errors
    if (error.code === 'ECONNABORTED' || !error.response) {
      throw new Error('Network error occurred');
    }
    return Promise.reject(error);
  });

  $payment.onError((error) => {
    $sentry.captureException(error);
  });

  $login.onError((error) => {
    $sentry.captureException(error);
  });
}
