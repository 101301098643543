import { getUpsellSequence } from '@/modules/upsell/application/get-upsell-sequence.use-case';
import {
  SET_UPSELL_CURRENT_COMPONENT_NAME,
  SET_UPSELL_LIST,
  SET_UPSELL_CURRENT_INDEX,
  SET_UPSELL_PHYSICAL_LIST,
  SET_UPSELL_PHYSICAL_CURRENT_INDEX,
} from '../mutation-types';

/**
 *  Set upsell current component name
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Upsell current component name
 * @return {String} Upsell current component name
 */
export function setUpsellCurrentComponentNameAction({ commit }, payload) {
  commit(SET_UPSELL_CURRENT_COMPONENT_NAME, payload);
}

/**
 *  Set upsell component list based on selected variant
 * @param {function} commit - The vuex commit function
 * @param {Array} payload - Upsell component list
 * @return {Array} Upsell component list
 */
export function setUpsellListAction({ commit }, { productType, pricingModel, region }) {
  const upsellSequence = getUpsellSequence({
    productType,
    pricingModel,
    region,
  });
  commit(SET_UPSELL_LIST, upsellSequence);
}

/**
 *  Set upsell list current index
 * @param {function} commit - The vuex commit function
 * @param {Number} payload - Upsell list current index
 * @return {Number} Upsell list current index
 */
export function setUpsellListCurrentIndexAction({ commit }, payload) {
  commit(SET_UPSELL_CURRENT_INDEX, payload);
}

/**
 *  Set upsell physical list
 * @param {function} commit - The vuex commit function
 * @param {Array} payload - upsell physical list
 * @return {Array} upsell physical list
 */
export function setUpsellPhysicalListAction({ commit }, payload) {
  commit(SET_UPSELL_PHYSICAL_LIST, payload);
}

/**
 *  Set upsell physical list current index
 * @param {function} commit - The vuex commit function
 * @param {Number} payload - Upsell physical list current index
 * @return {Number} Upsell physical list current index
 */
export function setUpsellPhysicalListCurrentIndexAction({ commit }, payload) {
  commit(SET_UPSELL_PHYSICAL_CURRENT_INDEX, payload);
}
