import * as actions from './actions';
import * as getters from './getters';
import { SET_PHONE_VERIFIED } from '@/store/mutation-types';

const mutations = {
  [SET_PHONE_VERIFIED](state, payload) {
    state.phoneVerified = payload;
  },
};

const state = () => ({
  phoneNumberVerified: false,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
