export default {
  name: 'quiz-new-lander-test',
  includedRoutes: [
    '/tour',
    '/tour/',
    // '/tour/optimal-weight-program',
    // '/tour/optimal-weight-program/',
    '/tour/weight-loss-over-40',
    '/tour/weight-loss-over-40/',
    '/tour/weight-loss-secret',
    '/tour/weight-loss-secret/',
    '/tour/womens-health-food-impact',
    '/tour/womens-health-food-impact/',
    '/tour/cholesterol-control',
    '/tour/cholesterol-control/',
    '/tour/anti-inflammatory-diet',
    '/tour/anti-inflammatory-diet/',
    '/tour/fast-weight-loss-for-women',
    '/tour/fast-weight-loss-for-women/',
    '/tour/macro-diet-for-women',
    '/tour/macro-diet-for-women/',
    '/tour/weight-loss-without-fasting',
    '/tour/weight-loss-without-fasting/',
    '/tour/metabolic-renewal-for-women',
    '/tour/metabolic-renewal-for-women/',
  ],
  variants: [
    {
      tour: 0,
      path: '/tour/0',
    },
    {
      tour: 127,
      path: '/tour/127',
    },
    {
      tour: 128,
      path: '/tour/128',
    },
  ],
};
