import { SET_HAS_LEAD_STATUS, SET_LEAD_EXPERIMENTS, SET_LEAD_UUID } from '../mutation-types';
import * as actions from './actions';
import * as getters from './getters';

const mutations = {
  [SET_HAS_LEAD_STATUS](state, payload) {
    state.lead = payload;
  },
  [SET_LEAD_UUID](state, payload) {
    state.uuid = payload;
  },
  [SET_LEAD_EXPERIMENTS](state, experiments) {
    state.experiments = experiments;
  },
};

const state = () => ({
  lead: null,
  uuid: null,
  experiments: {},
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
