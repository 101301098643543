/**
 * Get upsell current component name
 * @param {object} state - The vuex state object
 * @return {String} upsell current component name
 */
export const getUpsellCurrentComponentName = (state) => state.upsellCurrentComponentName;

/**
 * Get upsell component list
 * @param {object} state - The vuex state object
 * @return {Array} upsell component list
 */
export const getUpsellList = (state) => state.upsellList;

/**
 * Get upsell component list length
 * @param {object} state - The vuex state object
 * @return {Array} upsell component list length
 */
export const getUpsellListLength = (state) => state.upsellList.length;

/**
 * Get upsell list current index
 * @param {object} state - The vuex state object
 * @return {Number} upsell list current index
 */
export const getUpsellListCurrentIndex = (state) => state.upsellListCurrentIndex;

/**
 * Get upsell component list
 * @param {object} state - The vuex state object
 * @return {Array} upsell component list
 */
export const getUpsellPhysicalList = (state) => state.upsellPhysicalList;

/**
 * Get upsell component list length
 * @param {object} state - The vuex state object
 * @return {Array} upsell component list length
 */
export const getUpsellPhysicalListLength = (state) => state.upsellPhysicalList.length;

/**
 * Get upsell physical list current index
 * @param {object} state - The vuex state object
 * @return {Number} upsell physical list current index
 */
export const getUpsellPhysicalListCurrentIndex = (state) => state.upsellPhysicalListCurrentIndex;
