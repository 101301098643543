// import pricing from './pricing';
// import pricingLatam from './pricing-latam';
// import offerPageCheckoutUpdatedPricePoints from './offer-page-checkout-updated-price-points';
// import quizCompareCurrentControlVsControl3MonthsAgo from './quiz-compare-current-control-vs-control-3-months-ago';
import dummyOfferTest from './dummy-offer-test';
import dummyTourTest from './dummy-tour-test';
// import pricingTelcoUS from './pricing-telco-us-dev-2337';
// import pricingDevenDollarTrial from './pricing-seven-dollar-trial';
import pricingBrEbanx from './pricing-br-ebanx';
// import tourGlpOne from './tour-glp-one';
// import quizCheckoutGlp1IvimHealth from './quiz-checkout-glp1-ivim-health';
import quizNewLanderTest from './quiz-new-lander-test';
import pilates28DayChallengeNewCheckout from './pilates-28-day-challenge-new-checkout';
import pilates28DayChallengeNewSecondaryText from './pilates-28-day-challenge-new-secondary-text';
import applePayCheckoutDotCom from './apple-pay-checkout-dot-com';
import pricingPilatesWeekly from './pricing-pilates-weekly';
import nutritionNewCheckout from './nutrition-new-checkout';
import pilatesChallangeNewDesign from './fitness-new-checkout-new-design';
import nutritionChallangeNewDesign from './nutrition-new-checkout-new-design';
import tourBodyNutritionWeightLossReasonStep from './tour-body-nutrition-weight-loss-reason-step';
import pilates28DayChallengeEmailCta from './pilates-28-day-challenge-email-cta';
import tourBodyNutritionAddAnalysisStep from './tour-body-nutrition-add-analysis-step';
import tourBodyNutritionAddAnalysisStep1 from './tour-body-nutrition-add-analysis-step-1';
import pilates28DayChallengeAddAnalysisStep from './pilates-28-day-challenge-add-analysis-step';
import pricingPilatesWeeklyNewCheckout from './pricing-pilates-weekly-new-checkout';
import fitnessPilatesGooglePay from './fitness-pilates-google-pay';
import pilates28DayChallengeLandingPageBanner from './pilates-28-day-challenge-landing-page-banner';

const tests = [
  // Pending to be cleaned up
  // pricing,
  // pricingLatam,
  // quizLandingPage,
  // pricingTelcoUS,
  // tourGlpOne,
  // pricingDevenDollarTrial,
  // quizCheckoutGlp1IvimHealth,

  // Experiments stopped and pending to make a decision
  // offerPageCheckoutUpdatedPricePoints, // Wait some months until we make a conclusion
  // pricingTelcoUS,
  // pricingDevenDollarTrial,
  // tourGlpOne,

  // Dummy experiments
  dummyOfferTest,
  dummyTourTest,

  // Before launching these ones, they require adjustments

  // Ready to launch via config file
  pricingBrEbanx,
  quizNewLanderTest,
  pilates28DayChallengeNewSecondaryText,
  tourBodyNutritionWeightLossReasonStep,
  pilates28DayChallengeEmailCta,
  tourBodyNutritionAddAnalysisStep,
  pilates28DayChallengeAddAnalysisStep,
  pilates28DayChallengeLandingPageBanner,
  tourBodyNutritionAddAnalysisStep1,
  // New checkout experiments
  pilates28DayChallengeNewCheckout,
  applePayCheckoutDotCom,
  pricingPilatesWeekly,
  nutritionNewCheckout,
  pilatesChallangeNewDesign,
  nutritionChallangeNewDesign,
  pricingPilatesWeeklyNewCheckout,
  fitnessPilatesGooglePay,
];

export default tests;
