import Hotjar from '@hotjar/browser';

const SITE_ID = 2736527;
const HOTJAR_VERSION = 6;

export default function ({ route, $sentry }) {
  const pluginIsEnabled = process.env.NODE_ENV === 'production' && !process.env.IS_CI;

  if (process.client && pluginIsEnabled) {
    const isMedsTour = route.path === '/tour/meds';

    if (isMedsTour && !window.hotjarInitialized) {
      try {
        Hotjar.init(SITE_ID, HOTJAR_VERSION);
        window.hotjarInitialized = true;
      } catch (error) {
        $sentry.captureMessage(new Error(`Error initializing Hotjar: ${error}`, 'error'));
      }
    }
  }
}
