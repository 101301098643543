import { QUIZ_VARIANTS, REGIONS } from '@/services/constants';

/**
 * [Checkouts config by ID](https://github.com/reversegroupio/reversehealth-funnel/tree/staging/apps/checkout/src/modules/checkout/domain/checkouts)
 */
const NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS = Object.freeze({
  bf16bb: 'bf16bb',
  d6cFEb: 'd6cFEb',
  dcb9f6: 'dcb9f6',
  e15f5c: 'e15f5c',
  Ed77bd: 'Ed77bd',
  f27857: 'f27857',
  ff7U7n: 'ff7U7n',
  GWh4lr: 'GWh4lr',
  PnKrfW: 'PnKrfW',
  RyMZY1: 'RyMZY1',
});

/**
 * [Docs to current checkout IDs by product and region](https://www.notion.so/reversetech/New-CheckOut-Id-s-a6391e0986af41ecba55d1a0de267eef)
 */
export const NUTRITION_MENOPAUSE_MONTHLY_CHECKOUTS = Object.freeze([
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.bf16bb,
    region: REGIONS.au,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.d6cFEb,
    region: REGIONS.ca,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.dcb9f6,
    region: REGIONS.eu,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.e15f5c,
    region: REGIONS.join,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.Ed77bd,
    region: REGIONS.latam,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.f27857,
    region: REGIONS.nz,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.ff7U7n,
    region: REGIONS.sa,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.GWh4lr,
    region: REGIONS.uk,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.PnKrfW,
    region: REGIONS.br,
  },
  {
    tourIds: [
      QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
      QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
    ],
    checkoutId: NUTRITION_MENOPAUSE_MONTHLY_CHECKOUT_IDS.RyMZY1,
    region: REGIONS.us,
  },
]);
