
export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    isExperiment: {
      type: Boolean,
      default: false,
    },
  },
};
