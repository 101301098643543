
export default {
  name: 'Radio',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    modelValue: {
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
};
