/**
 * List of all giving email address that needed to be ignored
 * once we send any event via GTM, if you need to add more values please
 * use the following regex ex: /\b{domainName}\b/
 * @return {Array} blocked email list
 */
const blockedList = [/\breversegroup\b/];

/**
 * We are passing email to check if he is included in the {blockedList}
 * or not and return true or false if its blocked
 * @param {string} email - giving email value
 * @return {Boolean} if email is blocked or not
 */
const isEmailBlocked = (email) => blockedList.some((rule) => rule.test(email));

export { isEmailBlocked };
