import Cookies from 'cookie-universal';
import {
  SET_PAYMENT_CHECKOUT_RESPONSE,
  SET_PAYMENT_COMPONENT_NAME,
  SET_PAYMENT_SELECTED,
  SET_PAYMENT_TOKEN,
  SET_PAYMENT_CREATE_USER_PROMISE,
  SET_PAYMENT_CC_DETAILS,
  SET_PAYMENT_3D_SECURE_REQUIRED,
  SET_PAYMENT_3D_SECURE_URL,
  SET_PAYMENT_VARIANT,
  SET_PAYMENT_ORDER_SUMMARY_PAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST,
  SET_PAYMENT_AFFILIATES,
  SET_PAYMENT_COUPON_CODE,
  SET_PAYMENT_APPLE_PAY_INSTANCE,
  SET_PAYMENT_APPLE_PAY_NONCE,
  SET_PAYMENT_APPLE_WALLET_TOKEN,
  SET_PAYMENT_FIELDS_DOCUMENT_ID,
  SET_PAYMENT_ORDER_ID,
  SET_PAYMENT_TRANSACTION_ID,
} from '../mutation-types';
import { COOKIES } from '@/services/constants';
import URLS from '@/services/urls';
import { ABTestingNuxt } from '@/services/ab-testing';
import { Helpers } from '@/services/helpers';
import { getUtmObject } from '@/services/utm';
import { UPSELLS_STANDALONE_MAP } from '@/services/constants';

/**
 *  Fetch payment token after sending card information
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Card information, cc, cvc, name
 * @return {Promise} Contain the status and token, expire
 */
export async function fetchPaymentTokenAction({ commit }, payload) {
  const { data } = await this.$payment.$post(URLS.PAYMENT_TOKEN, payload);
  commit(SET_PAYMENT_TOKEN, data.token);
  return Helpers.resolvePromise(data);
}

/**
 *  Set the selected payment options that the user select and send out an object
 *  that contain the payment type and price value
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Selected payment object
 * @return {Object} Selected payment object
 */
export function setSelectedPaymentAction({ commit }, payload) {
  commit(SET_PAYMENT_SELECTED, payload);
}

/**
 *  Checkout handler that send payment payload object that contain all information
 *  about the payment
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @return {Promise} Contain the magic link and password
 */
export async function checkoutAction({ state, rootGetters, rootState, dispatch }) {
  let detectedCountryGetter = rootGetters['detected-country/getDetectedCountry'];

  if (!detectedCountryGetter) {
    try {
      await dispatch('detected-country/fetchDetectedCountryAction', null, {
        root: true,
      });

      detectedCountryGetter = rootGetters['detected-country/getDetectedCountry'];
    } catch (e) {
      console.error(e);
      this.$sentry.captureException(e);
    }
  }

  const { couponCode, paymentPayload, applePayNonce, applePayWalletToken } = state;
  const paymentPayloadCopy = Object.assign({}, paymentPayload);
  // Send country in case we don't have a Lead:
  const countryFromURL = Helpers.getCountryFromUrl();
  paymentPayloadCopy.siteCountry = countryFromURL;
  const experiments = ABTestingNuxt.getUserExperimentsFromCookies();
  paymentPayloadCopy.experiments = experiments;
  paymentPayloadCopy.detectedCountry = detectedCountryGetter || 'US';
  const landingPagePath = rootGetters['quiz/getQuizReferrerLink'];
  const quizReferrer = rootGetters['quiz/getLeadUserReferrerLink'];

  if (couponCode) {
    paymentPayloadCopy.promoCode = couponCode.discount.code;
  }

  const data = await this.$api.$post(URLS.PAYMENT_CHECKOUT_PAYMENT, {
    ...Helpers.cleanEmptyKeysFromObj(paymentPayloadCopy),
    country: countryFromURL,
    utmParams: getUtmObject(),
    paypalSticky: '1',
    host: this.$host,
    geo: this.$geo,
    landingPagePath,
    quizReferrer,
    ...(applePayNonce ? { nonce: applePayNonce } : {}),
    ...(applePayWalletToken ? { walletToken: applePayWalletToken } : {}),
  });

  const { uuid } = rootState['have-lead'];

  if (uuid && paymentPayloadCopy.email) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 7);

    const cookies = Cookies();
    cookies.set(
      COOKIES.userAttrs,
      { uuid, email: paymentPayloadCopy.email },
      {
        expires: expireDate,
        path: '/',
        secure: process.env.NODE_ENV !== 'development',
      },
    );
  }

  return Helpers.resolvePromise(data);
}

export async function fetchApplePayInstanceAction({ commit }) {
  try {
    const clientInstance = await window.braintree?.client.create({
      authorization: this.$config.braintreeClientAuthorization,
    });
    const applePayInstance = await window.braintree?.applePay.create({
      client: clientInstance,
    });

    commit(SET_PAYMENT_APPLE_PAY_INSTANCE, applePayInstance);
  } catch (error) {
    this.$sentry.captureException(error);
    commit(SET_PAYMENT_APPLE_PAY_INSTANCE, null);
  }
}

export async function setApplePayNonce({ commit }, payload) {
  commit(SET_PAYMENT_APPLE_PAY_NONCE, payload);
}

export async function setApplePayWalletToken({ commit }, payload) {
  commit(SET_PAYMENT_APPLE_WALLET_TOKEN, payload);
}

/**
 *  Set the payment variant
 * @param {function} commit - The vuex commit function
 * @param {number} payload - payment variant
 * @return {number} payment variant
 */
export function setPaymentVariant({ commit }, payload) {
  commit(SET_PAYMENT_VARIANT, payload);
}

/**
 * Detect country from IP and then call the checkoutUser api
 */
export async function checkoutUserAction({ state, commit, rootGetters }) {
  const detectedCountryGetter = rootGetters['detected-country/getDetectedCountry'];
  const detectedCountry = detectedCountryGetter || 'US';
  const { paymentPayload } = state;
  paymentPayload.siteCountry = Helpers.getCountryFromUrl();
  const experiments = ABTestingNuxt.getUserExperimentsFromCookies();
  paymentPayload.experiments = experiments;

  const payload = {
    detectedCountry,
    ...Helpers.cleanEmptyKeysFromObj(paymentPayload),
  };
  const data = await this.$api.$post(URLS.PAYMENT_CHECKOUT_USER, payload);
  commit(SET_PAYMENT_CHECKOUT_RESPONSE, data);
}

/**
 * Sync waiting for the user creation promises
 */
export async function waitUserCreation({ state, commit }) {
  if (state.createUserPromise) {
    await state.createUserPromise;
    commit(SET_PAYMENT_CREATE_USER_PROMISE, null);
  }
}

/**
 * Send magic link via SMS. Wait for the checkoutUserCreate action to complete first.
 */
export async function sendMagicLinkSmsAction({ state, commit }, { email, phoneNumber }) {
  await waitUserCreation({ state, commit });
  await this.$api.$post(URLS.SEND_WELCOME_SMS, {
    email,
    phoneNumber,
  });
}

/**
 * Change email and send magic link via email. Wait for the checkoutUserCreate action to complete first.
 */
export async function changeEmail({ state, commit }, args = {}) {
  const { currentEmail, newEmail } = args;
  if (state.createUserPromise) {
    await state.createUserPromise;
    commit(SET_PAYMENT_CREATE_USER_PROMISE, null);
  }
  const data = await this.$api.$post(URLS.CHANGE_EMAIL, {
    currentEmail,
    newEmail,
  });
  return Helpers.resolvePromise(data);
}

/**
 *  Set component name value
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Component name value
 * @return {String} Component name value
 */
export function setComponentName({ commit }, payload) {
  commit(SET_PAYMENT_COMPONENT_NAME, payload);
}

export async function cancelSubscription({ rootState }, args) {
  const resp = await this.$api.$post(URLS.CANCEL_SUBSCRIPTION, {
    ...args,
    source: rootState.common.sourceApp ? 'app' : 'web',
  });
  return resp;
}

export async function updateSubscriptionFromCancel(_, args) {
  const resp = await this.$api.$post(URLS.UPDATE_SUBSCRIPTION_FROM_CANCEL, args);
  return resp;
}

export async function pauseSubscriptionFromCancel(_, args) {
  const resp = await this.$api.$post(URLS.PAUSE_SUBSCRIPTION_FROM_CANCEL, args);
  return resp;
}

export async function updateUserPaymentData(_, args) {
  const resp = await this.$api.$post(URLS.UPDATE_USER_PAYMENT_DATA, args);
  return resp;
}

/**
 *  Set Credit card information
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Credit card information object
 * @return {Object} Credit card information
 */
export function setCcDetailsAction({ commit }, payload) {
  commit(SET_PAYMENT_CC_DETAILS, payload);
}

/**
 *  Set flag if 3d secure is required
 * @param {function} commit - The vuex commit function
 * @param {Boolean} payload - 3d secure status value
 * @return {Boolean} 3d secure status
 */
export function set3DSecureStatusAction({ commit }, payload) {
  commit(SET_PAYMENT_3D_SECURE_REQUIRED, payload);
}

/**
 *  Set 3d secure iframe url
 * @param {function} commit - The vuex commit function
 * @param {String} orderId - orderId
 * @return {String} 3d secure iframe url
 */
export function set3DSecureURL({ commit }, { orderId }) {
  commit(SET_PAYMENT_3D_SECURE_URL, URLS.THREE_D_SECURE_LINK(orderId));
}

/**
 *  Set paid order summary
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setPaidOrderSummary({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_PAID, payload);
}

/**
 *  Set unpaid order summary
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setUnpaidOrderSummary({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_UNPAID, payload);
}

/**
 *  Set unpaid order summary list
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setUnpaidOrderSummaryList({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST, payload);
}

/**
 *  Set affiliates
 * @param {function} commit - The vuex commit function
 * @param {boolean} payload - Affiliates
 */
export function setAffiliates({ commit }, payload) {
  commit(SET_PAYMENT_AFFILIATES, payload);
}

/**
 *  Set coupon code
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Coupon code
 */
export function setCouponCode({ commit }, payload) {
  commit(SET_PAYMENT_COUPON_CODE, payload);
}

/**
 *  Set Fields Document Id
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Brazil Document Id
 */
export function setFieldsDocumentId({ commit }, payload) {
  commit(SET_PAYMENT_FIELDS_DOCUMENT_ID, payload);
}

/**
 *  Validate coupon code
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @return {Promise} Coupon code configuration
 */
export async function validateCouponCode({ commit, state }, { code, isPaypal }) {
  try {
    const data = await this.$api.$get(URLS.CHECKOUT_PAYMENT_COUPON_CODE, {
      params: {
        code,
        email: state.paymentPayload.email,
        paymentMethod: isPaypal ? 'paypal' : state.paymentPayload.payment.method,
        paymentType: state.selectedPayment.type,
        paymentVariant: state.paymentPayload.variant,
        siteCountry: this.$countryCode,
      },
    });

    commit(SET_PAYMENT_COUPON_CODE, data);

    return data;
  } catch {
    commit(SET_PAYMENT_COUPON_CODE, null);
  }
}

export async function checkoutUpsellPaymentAction({ state, rootGetters, dispatch }) {
  let detectedCountryGetter = rootGetters['detected-country/getDetectedCountry'];

  if (!detectedCountryGetter) {
    try {
      await dispatch('detected-country/fetchDetectedCountryAction', null, {
        root: true,
      });

      detectedCountryGetter = rootGetters['detected-country/getDetectedCountry'];
    } catch (e) {
      console.error(e);
      this.$sentry.captureException(e);
    }
  }

  const { couponCode, paymentPayload } = state;
  const countryFromURL = Helpers.getCountryFromUrl();
  const experiments = ABTestingNuxt.getUserExperimentsFromCookies();
  paymentPayload.siteCountry = countryFromURL;
  paymentPayload.experiments = experiments;
  paymentPayload.detectedCountry = detectedCountryGetter || 'US';

  if (couponCode) {
    paymentPayload.promoCode = couponCode.discount.code;
  }

  const data = await this.$api.$post(URLS.PAYMENT_CHECKOUT_PAYMENT_UPSELL_STANDALONE, {
    ...Helpers.cleanEmptyKeysFromObj(paymentPayload),
    country: countryFromURL,
    utmParams: getUtmObject(),
    paypalSticky: '1',
    host: this.$host,
    geo: this.$geo,
    payment: {
      ...paymentPayload.payment,
      type: UPSELLS_STANDALONE_MAP[paymentPayload.payment.type],
    },
  });

  return Helpers.resolvePromise(data);
}

export function setOrderId({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_ID, payload);
}

export function setTransactionId({ commit }, payload) {
  commit(SET_PAYMENT_TRANSACTION_ID, payload);
}

export async function generateCheckoutToken(_, { tokenData }) {
  return await this.$api.$post(
    this.$config.checkoutTokensURL,
    {
      type: 'applepay',
      token_data: tokenData.paymentData,
    },
    {
      headers: {
        Authorization: `Bearer ${this.$config.checkoutPublicKey}`,
      },
    },
  );
}

export async function validateApplePaySession(_, { appleURL }) {
  return await this.$api.$post(URLS.CHECKOUT_PAYMENT_VALIDATE_APPLE_PAY_SESSION, {
    appleURL,
    domainName: window.location.hostname,
  });
}
