import Vue from 'vue';

const CORE_VALUES = { weight: 2.205, height: 2.54 };

const KG_TO_LBS = (value) => {
  if (!value) return;
  return (value * CORE_VALUES.weight).toFixed(0);
};

const LBS_TO_KG = (value) => {
  if (!value) return;
  return (value / CORE_VALUES.weight).toFixed(0);
};

const INCHES_TO_CM = (value) => {
  if (!value) return;
  return (value * CORE_VALUES.height).toFixed(0);
};

const INCHES_TO_CM_FLOAT = (value) => {
  if (!value) return;
  return value * CORE_VALUES.height;
};

const CM_TO_INCHES = (value) => {
  if (!value) return;
  return (value / CORE_VALUES.height).toFixed(0);
};

const CM_TO_FEET = (value) => {
  if (!value) return;
  const totalInches = Math.round(value / 2.54);
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return { feet, inches };
};

const FEET_INCH_TO_CM = (value) => {
  if (!value) return;
  const { feet, inches } = value;
  const totalInches = feet * 12 + inches;
  return Math.floor(totalInches * 2.54);
};

const ST_TO_KG = (value) => {
  return Math.floor(value * 6.35029);
};

const KG_TO_ST = (value) => {
  return Math.floor(value / 6.35029);
};

const KG_TO_ST_AND_LBS = (value) => {
  const KG_TO_LB = 2.20462;
  const POUNDS_IN_STONE = 14;

  const totalPounds = Math.round(value * KG_TO_LB);

  const totalStones = Math.floor(totalPounds / POUNDS_IN_STONE);

  const remainingPounds = Math.round(totalPounds % POUNDS_IN_STONE);

  return { stones: totalStones, pounds: remainingPounds };
};

const ST_AND_LBS_TO_KG = (stones, pounds) => {
  const STONE_TO_LB = 14;
  const LB_TO_KG = 0.453592;

  const totalPounds = stones * STONE_TO_LB + pounds;

  const totalKg = totalPounds * LB_TO_KG;

  return Math.floor(totalKg);
};

Vue.filter('kgToLbs', KG_TO_LBS);
Vue.filter('lbsToKg', LBS_TO_KG);
Vue.filter('inchesToCm', INCHES_TO_CM);
Vue.filter('inchesToCmFloat', INCHES_TO_CM_FLOAT);
Vue.filter('cmToInches', CM_TO_INCHES);
Vue.filter('cmToFeet', CM_TO_FEET);
Vue.filter('feetInchesToCm', FEET_INCH_TO_CM);
Vue.filter('stToKg', ST_TO_KG);
Vue.filter('kgToSt', KG_TO_ST);
Vue.filter('kgToStAndLbs', KG_TO_ST_AND_LBS);
Vue.filter('stAndLbsToKg', ST_AND_LBS_TO_KG);
