import Vue from 'vue';
import {
  SET_UPSELL_ORDERS,
  SET_UPSELL_ADDRESS,
  SET_UPSELL_UPDATED_ORDERS,
  SET_UPSELL_PAGE_PROPS,
  SET_UPSELL_PREVIOUS_ORDER_ID,
  SET_GLOBAL_EVENT_NAME,
  SET_UPSELL_DOWNSALE,
  SET_UPSELL_SUBSCRIPTION,
  SET_UPSELL_STANDALONE,
  SET_UPSELL_STANDALONE_BOUGHT,
  SET_UPSELL_YOGA_AND_HEALTHY_BACK,
} from '../mutation-types';
import * as actions from './actions';
import * as getters from './getters';

const mutations = {
  [SET_UPSELL_ORDERS](state, payload) {
    const index = state.upsellPayload.orders.findIndex((item) => item.type === payload.type);
    if (index !== -1) {
      Vue.set(state.upsellPayload.orders, index, payload);
    } else {
      state.upsellPayload.orders.push(payload);
    }
  },
  [SET_UPSELL_ADDRESS](state, payload) {
    state.upsellPayload.address = payload;
  },
  [SET_UPSELL_UPDATED_ORDERS](state, payload) {
    state.upsellPayload.orders = payload;
  },
  [SET_UPSELL_PAGE_PROPS](state, payload) {
    state.pageProps = payload;
  },
  [SET_UPSELL_PREVIOUS_ORDER_ID](state, payload) {
    state.upsellPayload.payment.previousOrderId = String(payload);
  },
  [SET_GLOBAL_EVENT_NAME](state, payload) {
    state.globalEventName = payload;
  },
  [SET_UPSELL_DOWNSALE](state, payload) {
    state.upsellPayload.upsellDownsale = payload;
  },
  [SET_UPSELL_SUBSCRIPTION](state, payload) {
    state.upsellPayload.upsellSubscription = payload;
  },
  [SET_UPSELL_STANDALONE](state, payload) {
    state.upsellStandalone = payload;
  },
  [SET_UPSELL_STANDALONE_BOUGHT](state, payload) {
    state.upsellStandaloneBought = payload;
  },
  [SET_UPSELL_YOGA_AND_HEALTHY_BACK](state, upsell) {
    state.upsellYogaAndHealthyBack = upsell;
  },
};

const state = () => ({
  globalEventName: '',
  donation: 'donation',
  familyMeal: 'family-meal',
  fastFoodMeal: 'fast-food-meal',
  fastFoodMealPilates: 'fast-food-meal-pilates',
  sweetTreatsMeal: 'sweet-treats-meal',
  premiumContent: 'premium-content',
  alcohol: 'alcohol',
  detox: 'detox',
  upsell12Month: 'upsell-12-month',
  upsell12MonthLifetime: 'upsell-lifetime',
  upsellYogaAndHealthyBack: 'yoga-and-healthy-back',
  dayMealPlan: 'day-meal-plan',
  pageProps: {},
  upsellPayload: {
    orders: [],
    address: null,
    payment: {
      previousOrderId: null,
    },
    upsellDownsale: false,
    upsellSubscription: false,
  },
  threedDVerify: {
    pan: null,
    month: null,
    year: null,
    amount: 0,
  },
  upsellStandalone: '',
  upsellStandaloneBought: false,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
