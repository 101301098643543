import createApp from './app.js'

import remoteConfigService from './service.remoteConfig.js'

const appConfig = {"apiKey":"AIzaSyBXby8mdYtGAuVBkqDYQo2I5doTgLQrA_A","authDomain":"reversehealth-web.firebaseapp.com","databaseURL":"https:\u002F\u002Freversehealth-web-default-rtdb.firebaseio.com","projectId":"reversehealth-web","storageBucket":"reversehealth-web.appspot.com","messagingSenderId":"320562764343","appId":"1:320562764343:web:754d976793c9d4ddce7db2","measurementId":"G-BW6C8KXQTD"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.client) {
    servicePromises = [
      remoteConfigService(session, firebase, ctx, inject),

    ]
  }

  const [
    remoteConfig
  ] = await Promise.all(servicePromises)

  const fire = {
    remoteConfig: remoteConfig
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}