import { SET_IS_FOOTER_SHOWN } from '../mutation-types';

/**
 *  Change footer status
 * @param {function} commit - The vuex commit function
 * @param {Boolean} payload - true / false value
 * @return {Boolean} Footer status if its shown or not
 */
export function changeFooterStatusAction({ commit }, payload) {
  commit(SET_IS_FOOTER_SHOWN, payload);
}
