import jwt from 'jsonwebtoken';
import { Helpers } from '@/services/helpers';
import { COOKIES } from '@/services/constants';

export default function ({ redirect, $cookies, store, query }) {
  const { token, redirect_url: redirectUrl, redirect_path: redirectPath } = query;

  try {
    const data = jwt.decode(token);
    const expiresAt = data.exp * 1000;

    // Token expired.
    if (expiresAt < Date.now()) {
      return redirect(Helpers.getLoginUrl(query));
    }

    store.dispatch('auth/setUserLoginEmailAction', data.email);
    store.dispatch('auth/setJwtData', data);

    $cookies.set(COOKIES.token, token, {
      expires: new Date(expiresAt),
      path: '/',
      secure: process.env.NODE_ENV !== 'development',
    });

    return redirect(redirectPath || redirectUrl || '/manage');
  } catch (e) {
    return redirect(Helpers.getLoginUrl(query));
  }
}
