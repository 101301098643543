import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
  includedRoutes: ['/tour/pilates-28-day-challenge', '/tour/pilates-28-day-challenge/'],
  variants: [
    {
      tour: 155,
      path: '/tour/155',
    },
    {
      tour: 180,
      path: '/tour/180',
    },
  ],
};
