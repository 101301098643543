import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PRICING_PILATES_WEEKLY_NEW_CHECKOUT,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  allocateOnClient: true,
  variants: [
    {
      pricing: 0,
    },
    {
      pricing: 1,
    },
    {
      pricing: 2,
    },
  ],
};
