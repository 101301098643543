import Vue from 'vue';
import { LANDING_PAGE_IDS, QUIZ_VARIANTS, EXPERIMENT_NAMES } from '@/services/constants';

Vue.mixin({
  data() {
    return {
      QUIZ_VARIANTS,
      LANDING_PAGE_IDS,
      EXPERIMENT_NAMES,
    };
  },
});
