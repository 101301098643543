import { getLangFromLocale } from '@/services/locale';
import { buildUrlParameters } from '@/services/url';
import { FITNESS_PILATES_WEEKLY_CHECKOUTS_EXPERIMENTS } from '@/modules/checkout/domain/fitness/pilates/weekly.checkouts';
import { FITNESS_PILATES_NOW_WEEKLY_CHECKOUTS_EXPERIMENTS } from '@/modules/checkout/domain/fitness/pilates-now/weekly.checkouts';

export const getCheckoutIdPathByTourIdAndPriceVariant = (
  { tourId, currentRegion, leadUuid, currentLocale, priceVariant = 0, affiliate = null },
  { onSuccess, onFailure },
) => {
  if (!leadUuid) {
    onFailure();
    return;
  }

  const targetCheckoutData =
    FITNESS_PILATES_WEEKLY_CHECKOUTS_EXPERIMENTS.find(
      (c) => c.region === currentRegion && c.tourIds.includes(tourId) && c.variant === priceVariant,
    ) ||
    FITNESS_PILATES_NOW_WEEKLY_CHECKOUTS_EXPERIMENTS.find(
      (c) => c.region === currentRegion && c.tourIds.includes(tourId) && c.variant === priceVariant,
    );

  if (!targetCheckoutData || !targetCheckoutData.checkoutId) {
    onFailure();
    return;
  }

  const locale = getLangFromLocale(currentLocale);

  const urlParams = buildUrlParameters({
    uuid: leadUuid,
    locale: locale,
    aff: affiliate,
  });

  const completeCheckoutPath = `${targetCheckoutData.checkoutId}/offers?${urlParams}`;

  onSuccess(completeCheckoutPath);
};
