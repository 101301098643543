import * as actions from './actions';
import { SET_MEDS_SHIPPING } from '../mutation-types';

const mutations = {
  [SET_MEDS_SHIPPING](state, payload) {
    state.shippingInfo = payload;
  },
};

const state = () => ({
  shippingInfo: {},
});

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
