export default async function (context, inject) {
  try {
    const response = await context.$axios.get(context.$config.abTestingKv);
    const jsonData = response.data;

    inject('kvExperiments', Array.isArray(jsonData) ? jsonData : []);
  } catch (e) {
    inject('kvExperiments', []);
  }
}
