import URLS from '@/services/urls';

export async function requestPasswordReset(context, { email }) {
  const result = await this.$api.$post(URLS.REQUEST_TAPPTITUDE_PASSWORD_RESET, {
    email,
  });

  return result;
}

export async function updatePassword(context, { token, password }) {
  const result = await this.$api.$post(URLS.UPDATE_TAPPTITUDE_PASSWORD, {
    token,
    newPassword: password,
  });

  return result;
}
