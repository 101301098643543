import { checkoutApi } from '@/modules/apis/checkout-api';

export const checkoutRepository = {
  getCheckoutIdByRegionAndQuizId: async ({ region, vertical, type, pricingModel, variant }) => {
    const { data } = await checkoutApi.get('/checkout-id', {
      params: {
        region,
        vertical,
        type,
        pricingModel,
        variant,
      },
    });

    return data;
  },
};
