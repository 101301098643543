const merge = require('lodash/merge');
const usConfig = require('./regions/default.config.json');
const quiz = require('./quiz/el.json');
const usCopys = require('./translations/el.json');
const config = require('./regions/eu.config.json');
const copys = require('./translations/el-EU.json');
const price = require('./prices/eu.json');
const units = require('./translations/el.metric.json');

const locale = {};
merge(locale, usConfig);
merge(locale, usCopys);
merge(locale, units);
merge(locale, config);
merge(locale, quiz);
merge(locale, price);
merge(locale, copys);

export default locale;
