export default {
  name: 'apple-pay-checkout-dot-com',
  includedRoutes: ['/checkout', '/checkout/'],
  allocateOnClient: true,
  variants: [
    {
      variant: 0,
    },
    {
      variant: 1,
    },
    {
      variant: 2,
    },
  ],
};
