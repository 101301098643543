import Cookies from 'cookie-universal';
import { COOKIES } from './constants';
import { useDomain } from '../composables/useDomain';

export class Helpers {
  /**
   * Check if the environment in development mode
   * @type {boolean}
   */
  static isProduction = process.env.NODE_ENV === 'production';

  /**
   * resolvePromise function will just pass the data and resolve or reject
   * @param data
   * @return {Promise<unknown>}
   */
  static resolvePromise(data) {
    return new Promise((resolve, reject) => {
      try {
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * mapFilters function will help you the shorten the way for calling vue filter
   * ex: this.$options.filters['filterName'] instead we are going to use it like that:
   * ...mapFilters(['filterName1', 'FilterName2])
   * @param filters - filter name
   * @return {any} - the filtered value
   */
  static mapFilters(filters) {
    return filters.reduce((result, filter) => {
      result[filter] = function (...args) {
        return this.$options.filters[filter](...args);
      };
      return result;
    }, {});
  }

  /**
   * Loop through the object and filter all the values that doesn't have null value
   * @param obj - original object
   * @return {Object} - New object without null value
   */
  static cleanEmptyKeysFromObj(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }

  static getCountryFromUrl() {
    const domain = useDomain(window.location.hostname);

    const countryCode = domain.getCountryCode();

    return countryCode;
  }

  static fixEmailAddress(email) {
    let result = email.trim().toLowerCase();
    result = result.replace(/\.(con|coom|comm|cim|ccom|vom|xom|comcom|com[a-z]|com[0-9])$/, '.com');
    result = result.replace(/\.(met|net[a-z]|net[0-9])$/, '.net');
    result = result.replace(/\.co\.(uk9|ik)$/, '.co.uk');
    return result;
  }

  static loggedin() {
    if (!process.client) {
      return false;
    }

    const cookies = Cookies();
    const token = cookies.get(COOKIES.token);

    return Boolean(token);
  }

  static logout() {
    if (!process.client) {
      return;
    }
    const cookies = Cookies();
    cookies.remove(COOKIES.token);
  }

  static getLoginUrl({ redirectUrl, redirectPath }) {
    return {
      path: '/login',
      query: {
        ...(redirectUrl && { redirect_url: redirectUrl }),
        ...(redirectPath && { redirect_path: redirectPath }),
      },
    };
  }
}
